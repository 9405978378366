<template>
  <div class="AddContact">
    <CModal
      :show.sync="isContactCreated"
      :no-close-on-backdrop="true"
      title="Contact créé"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre contact a bien été ajouté 👍
      </p>
      <p>
        Tous les collaborateurs de votre cabinet pourront maintenant accéder aux informations de votre contact, et créer des missions ou des clients liés à ce contact.
      </p>
      <template #footer>
        <CButton @click="resetNewContact" color="primary">Ajouter un nouveau contact</CButton>
        <CButton @click="$router.push('/manage-contacts')" color="dark">Retour à la liste des contacts</CButton>
      </template>
    </CModal>


    <loading
      :active.sync="isConflitPreventingLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#B40404">
      <div class="after-icon mt-3" slot="after">
        <strong>Prévention des confilts d'intérêts et duplicata</strong>
      </div>
    </loading>

    <CRow>
      <CCol>
        <h1> Ajouter un nouveau contact </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CForm v-on:submit.prevent="createContact()">

          <CRow>
            <CCol sm="4">
              <CSelect
                :value.sync="newContactSex"
                @input="$v.newContactSex.$touch()"
                :isValid="$v.newContactSex.$dirty ? !$v.newContactSex.$error : null"
                :options='sexArrayForm'
                custom
              />
            </CCol>
            <CCol sm="4">
              <CInput
                v-model="newContactFirstName" type="text"
                maxlength="30"
                placeholder="Prénom"
                @input="$v.newContactFirstName.$touch()"
                :isValid="$v.newContactFirstName.$dirty ? !$v.newContactFirstName.$error : null"
                invalid-feedback="Le prénom doit comporter entre 2 et 30 caractères"
              >
              </CInput>
            </CCol>
            <CCol sm="4">
              <CInput
                v-model="newContactLastName" type="text"
                placeholder="Nom"
                maxlength="150"
                @change="searchContactByLastName"
                @input="$v.newContactLastName.$touch()"
                :isValid="$v.newContactLastName.$dirty ? !$v.newContactLastName.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow v-if="!$v.newContactLastName.$invalid && duplicateContactsLastName.length > 0 && duplicateContactsLastNameHasBeenSearched">
            <CCol class="text-danger">
              <strong><CIcon name="cil-warning"/> Prévention des conflits d'intérêts :</strong> votre contact existe peut-être déjà dans votre base :
              <ul>
                <li v-for="duplicateContact in duplicateContactsLastName" v-bind:key="duplicateContact.id">
                  {{duplicateContact.first_name}} {{duplicateContact.last_name}} <router-link :to="'/contact-details/' + String(duplicateContact.id)" target="_blank">Accéder au profil</router-link>
                </li>
              </ul>
            </CCol>
          </CRow>

          <CRow class="mb-3" v-if="!$v.newContactLastName.$invalid && duplicateContactsLastName.length == 0 && duplicateContactsLastNameHasBeenSearched">
            <CCol class="text-success">
              <strong><CIcon name="cil-check"/> Prévention des conflits d'intérêts :</strong> votre contact semble bien ne pas déjà être dans votre base.
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="6">
              <CInput
                label="Email"
                type="email"
                maxlength="150"
                placeholder="Email"
                v-model="newContactEmail"
                @change="searchContactByEmail"
                @input="$v.newContactEmail.$touch()"
                :isValid="$v.newContactEmail.$dirty ? !$v.newContactEmail.$error : null"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CInput
                label="Fonction (facultatif)"
                type="text"
                maxlength="149"
                placeholder="Fonction"
                v-model="newContactFonction"
                @input="$v.newContactFonction.$touch()"
                :isValid="$v.newContactFonction.$dirty ? !$v.newContactFonction.$error : null"
              >
              </CInput>
            </CCol>
          </CRow>

          <CRow v-if="!$v.newContactEmail.$invalid && duplicateContactsEmail.length > 0 && duplicateContactsEmailHasBeenSearched">
            <CCol class="text-danger">
              <strong><CIcon name="cil-warning"/> Prévention des conflits d'intérêts :</strong> cet email existe déjà dans votre base :
              <ul>
                <li v-for="duplicateContact in duplicateContactsEmail" v-bind:key="duplicateContact.id">
                  {{duplicateContact.first_name}} {{duplicateContact.last_name}} <router-link :to="'/contact-details/' + String(duplicateContact.id)" target="_blank">Accéder au profil</router-link>
                </li>
              </ul>
            </CCol>
          </CRow>

          <CRow>
            <CCol lg="6">
              <label>Date de naissance (facultatif) </label>
              <CRow>
                <CCol sm="3">
                  <CSelect
                    :value.sync="newContactBirthdateDay"
                    :options='daysArrayForm'
                    custom
                  />
                </CCol>
                <CCol sm="6">
                  <CSelect
                    :value.sync="newContactBirthdateMonth"
                    :options='monthsArrayForm'
                    custom
                  />
                </CCol>
                <CCol sm="3">
                  <CSelect
                    :value.sync="newContactBirthdateYear"
                    :options='yearsArrayForm'
                    custom
                  />
                </CCol>
              </CRow>
              <small class="text-danger" v-if="$v.birthdateFormat.$invalid ">Veuillez saisir une date valide</small>

            </CCol>
            <CCol lg="6">
              <div role="group" class="form-group">
                <label for="addContactVuePhoneNumber" class>
                  Numéro de téléphone
                </label>
                <vue-phone-number-input
                  id="addContactVuePhoneNumber"
                  name="addContactVuePhoneNumber"
                  v-model="newContactPhoneNumber"
                  :default-country-code='newContactPhoneNumberResult.countryCode'
                  :preferred-countries="['FR', 'BE', 'DE']"
                  valid-color='#28a745'
                  error-color='#dc3545'
                  :translations="{
                    countrySelectorLabel: 'Code pays',
                    countrySelectorError: 'Choisir un pays',
                    phoneNumberLabel: 'Numéro de téléphone',
                    example: 'Exemple :'
                  }"
                  @update='newContactPhoneNumberResult = $event'
                  :required='false'
                />
                <small class="text-danger" v-if="newContactPhoneNumberResult.isValid == false">Veuillez rentrer un numéro de téléphone valide</small>
              </div>

            </CCol>
          </CRow>

          <CRow class="mb-2">
            <CCol sm="6">
              Souhaitez-vous affecter votre contact à un client déjà dans votre base ?
            </CCol>
            <CCol sm="5">
              <CInputRadioGroup
                :checked.sync="wantsToAddClient"
                :custom="true"
                :inline="true"
                :options='wantsToAddClientArrayForm'
              />
            </CCol>
          </CRow>

          <div v-if="wantsToAddClient == 'true'">
            <span> Votre client choisi : </span>
            <strong v-if="newContactClientObject.id != ''"> {{newContactClientObject.name}} </strong>
            <strong v-else> Aucun </strong>

            <vue-bootstrap-typeahead
              class="mt-2"
              v-model="newContactClientName"
              :data="clients"
              :serializer="item => item.name"
              @hit="newContactClientObject = $event"
               placeholder="Chercher votre client par nom"
            />
            <small class="text-muted">
              Vous ne trouvez pas votre client ?
            </small>
            <router-link :to="{ name: 'Ajouter un nouveau client'}">
              <small class="text-muted">Ajouter un nouveau client en cliquant ici</small>
            </router-link>
          </div>


          <CTextarea
            label="Observations"
            v-model="newContactObservations" type="text"
            placeholder="Vous pouvez renseigner des observations si nécessaire"
            maxlength="300"
            @input="$v.newContactObservations.$touch()"
            :isValid="$v.newContactObservations.$dirty ? !$v.newContactObservations.$error : null"
          >
          </CTextarea>
          <CRow class="mt-4">
            <CCol class="text-center">
              <CButton
                type="submit" :color="createContactButtonStyle"
                shape="pill" block class="px-4 mb-4" size="lg"
                :disabled="$v.newContactSex.$invalid || $v.newContactFirstName.$invalid || $v.newContactLastName.$invalid || $v.newContactEmail.$invalid || $v.newContactFonction.$invalid || $v.birthdateFormat.$invalid || $v.newContactPhoneNumberResult.$invalid || $v.newContactObservations.$invalid || createContactInProcess">
                <CSpinner size="sm" label="update profile spinner" v-if="createContactInProcess"></CSpinner>
                {{ createContactButtonText }}
              </CButton>
            </CCol>
          </CRow>
        </CForm>


      </CCardBody>
    </CCard>
  </div>
</template>

<script>

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email, integer} from 'vuelidate/lib/validators'
import { isDateOrBlank } from '@/validators/validators'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import { APIUserConnected } from '@/api/APIUserConnected'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css'

const apiUserConnected = new APIUserConnected()


export default {
  name: 'AddContact',
  components: {
    VueBootstrapTypeahead,
    Loading
  },
  mixins: [
    validationMixin,
  ],
  data: function () {
    return {
      // ----- Create Contact ---
      newContactSex: 'H',
      newContactFirstName: '',
      newContactLastName: '',
      newContactEmail: '',
      newContactFonction: '',
      newContactBirthdateYear: '',
      newContactBirthdateMonth: '',
      newContactBirthdateDay: '',
      newContactPhoneNumber: null,
      newContactPhoneNumberResult: {
        countryCode: "FR",
        isValid: false
      },
      newContactObservations: '',

      wantsToAddClient: 'false',
      clients: [],
      newContactClientName: '',
      newContactClientObject: {
        id: '',
        name: ''
      },

      createContactButtonText: 'Ajouter un nouveau contact',
      createContactButtonStyle: 'outline-primary',
      createContactInProcess: false,

      sexArrayForm: [
        { label: 'Monsieur', value: 'H' },
        { label: "Madame", value: 'F' },
      ],

      wantsToAddClientArrayForm: [
        { label: 'Oui', value: 'true' },
        { label: "Non", value: 'false' },
      ],

      isContactCreated: false,

      isSearchingContactByLastName: false,
      duplicateContactsLastNameHasBeenSearched: false,
      duplicateContactsLastName : [],

      isSearchingContactByEmail: false,
      duplicateContactsEmailHasBeenSearched: false,
      duplicateContactsEmail : [],

      monthsArrayForm: [
        { label: '', value: '' },
        { label: 'Janvier', value: '01' },
        { label: 'Février', value: '02' },
        { label: 'Mars', value: '03' },
        { label: 'Avril', value: '04' },
        { label: 'Mai', value: '05' },
        { label: 'Juin', value: '06' },
        { label: 'Juillet', value: '07' },
        { label: 'Août', value: '08' },
        { label: 'Septembre', value: '09' },
        { label: 'Octobre', value: '10' },
        { label: 'Novembre', value: '11' },
        { label: 'Décembre', value: '12' },
      ],

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isConflitPreventingLoading () {
      if (this.isSearchingContactByLastName || this.isSearchingContactByEmail) {
        return true
      }
      return false
    },
    authHeaders () {
      return apiUserConnected.getAuthHeaders(this.token)
    },

    // ---- Date
    daysArrayForm () {
      var days = [{'label': '', 'value': ''}]
      for (var i=1; i<=31; i++) {
        var value = String(i)
        if (i <= 9) {
          value = "0" + String(i)
        }
        days.push({'label': value, 'value': value})
      }
      return days
    },

    yearsArrayForm () {
      var years = [{'label': '', 'value': ''}]
      for (var i=1945; i<=2010; i++) {
        var value = String(i)
        years.push({'label': value, 'value': value})
      }
      return years
    },

    birthdateFormat () {
      if (this.newContactBirthdateYear == '' && this.newContactBirthdateMonth == '' && this.newContactBirthdateDay == '') {
        return ''
      }
      return this.newContactBirthdateYear + '-' + this.newContactBirthdateMonth + '-' + this.newContactBirthdateDay
    }
  },
  validations: {
    newContactSex: {
      required
    },
    newContactFirstName: {
      required,
      maxLength: maxLength(30),
      minLength: minLength(2)
    },
    newContactLastName: {
      required,
      maxLength: maxLength(150),
      minLength: minLength(2)
    },
    newContactEmail: {
      required,
      email,
      maxLength: maxLength(249),
      minLength: minLength(2)
    },
    newContactFonction: {
      minLength: minLength(2),
      maxLength: maxLength(149)
    },
    birthdateFormat: {
      validDate(value) {
        return isDateOrBlank(value)
      }
    },

    newContactPhoneNumberResult: {
      countryCode: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2)
      },
      isValid: {
        isValid(value) {
          return value
        }
      },
      nationalNumber: {
        required,
        integer
      }
    },
    newContactObservations: {
      minLength: minLength(2),
      maxLength: maxLength(300)
    },
  },
  watch: {
    // When the query value changes, fetch new results from
    // the API - in practice this action should be debounced
    newContactClientName(newQuery) {
      if (newQuery == '') {
        this.clients = []
        this.newContactClientObject = {
          id: '',
          name: ''
        }
      }
      else if (newQuery.length >= 2) {
        apiUserConnected.searchClientByName(this.token, newQuery)
        .then((res) => {
          this.clients = res.data
        })
      }
    }
  },
  methods: {
    // ---------- Create contact -----------
    createContact () {
      this.createContactButtonText = 'Création en cours'
      this.createContactButtonStyle = 'secondary'
      this.createContactInProcess = true
      apiUserConnected.createNewContact(this.token,
        this.newContactClientObject.id, this.newContactSex, this.newContactFirstName, this.newContactLastName,
        this.newContactEmail, this.newContactFonction, this.birthdateFormat, this.newContactPhoneNumberResult.countryCode,
        this.newContactPhoneNumberResult.nationalNumber, this.newContactObservations)
      .then(() => {
        this.isContactCreated = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.createContactButtonText = 'Ajouter un nouveau contact'
        this.createContactButtonStyle = 'outline-primary'
        this.createContactInProcess = false
      })
    },

    resetNewContact() {
      this.newContactSex = 'H'
      this.newContactFirstName = ''
      this.newContactLastName = ''
      this.newContactEmail = ''
      this.newContactFonction = ''
      this.newContactBirthdateYear = '',
      this.newContactBirthdateMonth = '',
      this.newContactBirthdateDay = '',
      this.newContactPhoneNumber = ''
      this.newContactPhoneNumberResult = {
        countryCode: 'FR',
        isValid: false
      }
      this.wantsToAddClient = 'false'
      this.newContactClientObject = {
        id: '',
        name: ''
      }
      this.newContactObservations = ''
      this.isContactCreated = false
    },


    searchContactByLastName () {
      if (this.$v.newContactLastName.$invalid) {
        return null
      }
      else {
        this.isSearchingContactByLastName = true
        apiUserConnected.searchContactByLastName(this.token, this.newContactLastName)
        .then((result) => {
          this.duplicateContactsLastName = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isSearchingContactByLastName = false
          this.duplicateContactsLastNameHasBeenSearched = true
        })
      }
    },

    searchContactByEmail () {
      if (this.$v.newContactEmail.$invalid) {
        return null
      }
      else {
        this.isSearchingContactByEmail = true
        apiUserConnected.searchContactByEmail(this.token, this.newContactEmail)
        .then((result) => {
          this.duplicateContactsEmail = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isSearchingContactByEmail = false
          this.duplicateContactsEmailHasBeenSearched = true
        })
      }
    }
  }
}
</script>
